import './TableStyles.css';
import { useNavigate, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import Modal from '@mui/material/Modal';
import PdfViewer from '../../Elements/PDFViewer/PDFViewer.js';
import { Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import APIService from '../../Util/APIService.js'
function TableExpandableButtonRow(props) {
    const navigate = useNavigate();
    const [pdfFiles, setPdfFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDocuments, setShowDocuments] = useState(false);

    useEffect(() => {
        fetchPaymentDocs();
    }, []);

    async function fetchPaymentDocs() {
        setLoading(true);
        if (props.data.hasDocuments != null) {
            //TODO this should be changed to just a getDocs call
            const result = await APIService.get("payments/GetPayment/" + props.data.IDContainer).then(res => {
                var pdfFileTemp = [];
                if (res.data.Payment != null) {
                    for (var i = 0; i < res.data.Payment.Documents.length; i++) {
                        pdfFileTemp.push({ title: res.data.Payment.Documents[i].Description, data: res.data.Payment.Documents[i].Base64File })
                    }
                    setPdfFiles(pdfFileTemp);
                }
            })
                .catch(error => {
                    setLoading(false);
                    navigate('/ErrorPage', { state: error.message });
                });
        }
        else {
            props.data.hasDocuments = false;
        }
    }

    // State to control the modal visibility
    const [openPDF, setOpenPDF] = useState(false);

    // Function to handle button click to navigate
    function TableButtonClick(selected, route) {
        navigate('' + route, { state: { itemID: props.data.IDContainer } });
    }

    // Function to open the modal
    const handleOpenPDF = () => {
        setOpenPDF(true);
    };

    // Function to close the modal
    const handleClosePDF = () => {
        setOpenPDF(false);
    };

    return (
        <div className="table-button-bar">
            {/* Existing buttons */}
            {props.data.dataContainer.map((value, index) => {
                return (
                    <button
                        key={index}
                        className="table-button-group-button mt-2"
                        onClick={() => TableButtonClick(props.data.IDContainer, value.route)}
                    >
                        {value.description}
                    </button>
                );
            })}

            {/* New button for documents */}
            {props.data.hasDocuments &&
                <Tooltip title="Documents">
                    <Button className="doc-float-right" variant="light" onClick={handleOpenPDF}>
                        <DocumentScannerIcon></DocumentScannerIcon>
                    </Button>
                </Tooltip>}

            {/* Modal to display PDF viewer */}
            <Modal
                open={openPDF}
                onClose={handleClosePDF}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <PdfViewer
                        pdfs={pdfFiles} // Assuming you pass the PDF files as a prop to this component
                        onCancel={handleClosePDF}   // Handling modal close inside PdfViewer
                        showLoading={loading}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default TableExpandableButtonRow;