/// <reference path="../../../elements/otpasscode/otpasscode.js" />
/// <reference path="../../../elements/tables/tableexpandablebuttonrow.js" />
import { Button, Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import '../../../Styles/Shared/content-page.css';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import APIService from '../../../Util/APIService.js'
import moment from "moment";
import PdfViewer from '../../../Elements/PDFViewer/PDFViewer.js';
import { useLocation, useNavigate, Outlet, BrowserRouter as Router, Routes, Route, Link as RouterLink } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import styles from "./PaymentView.module.css"
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tooltip from '@mui/material/Tooltip';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { setError, clearError } from '../../../Store/ErrorMessageSlice';
import { setSuccess, clearSuccess } from '../../../Store/SuccessMessageSlice';
import { setWarning, clearWarning } from '../../../Store/WarningMessageSlice';
import { useSelector, useDispatch } from "react-redux"
import { actions } from '../../../Store/index';
import OTPasscode from '../../../Elements/OTPasscode/OTPasscode.js';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PreviewIcon from '@mui/icons-material/Preview';
import { setRedirectPreviousRoute, clearRedirectPreviousRoute } from '../../../Store/RedirectPreviousRouteSlice';


function PaymentView(props) {
    const location = useLocation();
    const [payment, setPayment] = useState({ Payer: {}, Payee: {} });
    const [paymentPDF, setPaymentPDF] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pdfFiles, setPdfFiles] = useState([]);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openReject, setOpenReject] = React.useState(false);
    const [rejectReason, setRejectReason] = React.useState("");
    const [generalReason, setGeneralReason] = React.useState(0);
    const [stagesList, setStagesList] = React.useState([]);
    const [altStagesList, setAltStagesList] = React.useState([]);
    const [stagesProgress, setStageProgress] = React.useState(0);
    const [showCutoffAlert, setShowCutoffAlert] = useState(false);
    const [showPins, setShowPins] = useState(false);
    const navigate = useNavigate();

    const [openPDF, setOpenPDF] = React.useState(false);
    const handleOpen = () => { setOpenPDF(true); handleExportAudit(); };
    const handleClose = () => setOpenPDF(false);
    const dispatch = useDispatch();
    const [useAlternateLabels, setUseAlternateLabels] = useState(false); // State to toggle labels

    // Function to toggle labels when the button is clicked
    const toggleLabels = () => {
        setUseAlternateLabels(!useAlternateLabels);
    };
    async function fetchPayment() {
        setLoading(true);
        const result = await APIService.get("payments/GetPayment/" + location.state.itemID).then(res => {
            setPayment(res.data.Payment);
            var pdfFileTemp = [];
            for (var i = 0; i < res.data.Payment.Documents.length; i++) {
                pdfFileTemp.push({ title: res.data.Payment.Documents[i].Description, data: res.data.Payment.Documents[i].Base64File })
            }
            setPdfFiles(pdfFileTemp);
            if (res.data.Payment.CutoffTime !== null) {
                setShowCutoffAlert(true)
            }
            var stages = [];
            var altStages = []
            var stageProgress = 0
            for (var i = 0; i < res.data.Payment.Stages.length; i++) {
                stages.push(res.data.Payment.Stages[i].Description);
                altStages.push(res.data.Payment.Stages[i].UserName);
                if (res.data.Payment.Stages[i].Timestamp != null) {
                    stageProgress++;
                }
            }
            setStagesList(stages)
            setAltStagesList(altStages)
            setStageProgress(stageProgress);
            setLoading(false);
        })
            .catch(error => {
                navigate('/ErrorPage', { state: error.message });
            });

        const resultPdf = await APIService.get("payments/GetPaymentInvoice/" + location.state.itemID).then(res => {
            setPaymentPDF(res.data);
            setLoading(false);
        })
            .catch(error => {
                navigate('/ErrorPage', { state: error.message });
            });
    }

    useEffect(() => {

        fetchPayment();
    }, []);

   
    //Events
    const handleReasonChange = (event) => {
        setRejectReason(event.target.value);
    };


    const handleGeneralReasonChange = (event) => {
        setGeneralReason(event.target.value);
    };

    //Audit Events
    const handleExportAudit = async () => {
        // Perform your export logic here
        var parameterData = 'Payment Documents Print/View: ' + payment.PaymentDescription;
        const result = await APIService.post("User/Audit", parameterData).then(res => { });
    };

    const handleApproveAudit = async () => {
        // Perform your export logic here
        var parameterData = 'Payment Approval: ' + payment.PaymentDescription;
        const result = await APIService.post("User/Audit", parameterData).then(res => { });
    };

    const handleRejectAudit = async () => {
        // Perform your export logic here
        var parameterData = 'Payment Reject: ' + payment.PaymentDescription;
        const result = await APIService.post("User/Audit", parameterData).then(res => { });
    };

    // button clicks

    const handleComingSoonOpen = () => {
        alert("This will be coming soon.");
    };

    const handleClickOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setShowPins(false);
        setOpenConfirm(false);
    };

    const handleCloseConfirmComplete = () => {
        setOpenConfirm(false);
        confirmPayment(null);
    };

    const handleClickOpenReject = () => {
        setOpenReject(true);
    };

    const handleCloseReject = () => {
        setOpenReject(false);
    };

    const handleCloseRejectionComplete = () => {
        rejectPayment()
        setOpenReject(false);
    };

    //Api calls
    async function rejectPayment() {

        var info = generalReason + ": " + rejectReason;
        setLoading(true);
        var paymentID = location.state.itemID
        var dataObject = { questionnaireIds: [paymentID], reason: info, code: "" }
        dispatch(setRedirectPreviousRoute("/"))
        handleRejectAudit();
        navigate('/AuthorisationConfirmation', { state: { previous: "/", routing: "Payments/RejectPayments", routeObject: dataObject } });
    }

    async function confirmPayment() {

        setLoading(true);
        var paymentID = location.state.itemID
        var dataObject = { questionnaireIds: [paymentID], reason: "", code: "" }
        dispatch(setRedirectPreviousRoute("/"))
        handleApproveAudit();
        navigate('/AuthorisationConfirmation', { state: { previous: "/", routing: "Payments/ConfirmPayments", routeObject: dataObject } });
    }


    const steps = [
        'Payment Created',
        'Payment reviewed',
        'Internal Confirmation',
        'Portal Confirmation',
    ];


    return (
        <div>
            <Dialog sx={{
                height: 'auto !important',
                zIndex: "1000 !important"
            }} fullscreen open={openReject} onClose={handleCloseReject}>
                <DialogTitle>Reject Payment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do You wish to reject the payment?
                    </DialogContentText>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className={styles.reasonContainer}>
                            <InputLabel htmlFor="ReasonID">
                                Reason<span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <Select
                                id="ReasonID"
                                value={generalReason}
                                onChange={handleGeneralReasonChange}
                            >
                                <MenuItem value={0} >
                                    <em>Select Reason</em>
                                </MenuItem>
                                <MenuItem value={'Duplicate'}>Duplicate</MenuItem>
                                <MenuItem value={'Incorrect Info'}>Incorrect Info</MenuItem>
                                <MenuItem value={'Not Required'}>Not Required</MenuItem>
                                <MenuItem value={'Other'}>Other</MenuItem>
                            </Select>
                        </div>
                        <div className={styles.reasonContainer}>

                            <InputLabel htmlFor="ReasonID">
                                Additional Info <span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <TextField id="Reasoning" variant="outlined" value={rejectReason} onChange={handleReasonChange} />

                        </div>


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReject}>Cancel</Button>
                    <Button disabled={ generalReason == 0} onClick={handleCloseRejectionComplete}>Reject</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Approve Payment
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className={styles.authorisationTitle}>On 'Approve' Any payment/cuttoff dates needing amendment will be changed to next available day</div>
                        Do you wish to confirm the payment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Cancel</Button>
                    <Button onClick={handleCloseConfirmComplete} autoFocus>
                        Approve
                    </Button>
                </DialogActions>
            </Dialog>

           
            <div className="nav-action-bar hide-on-portrait-phone">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" component={RouterLink} color="inherit" to="/PaymentList">
                        {!payment.CanSignOff ? "Payment List" : "Authorisation List" }
                    </Link>
                    <Link underline="none" color="inherit">
                        {!payment.CanSignOff ? " Payment" : " Authorisation Payment"}
                    </Link>
                    <Link underline="none" color="inherit">
                        {payment.PaymentDescription }
                    </Link>
                </Breadcrumbs>
                   
                <div className="action-button-position">
                    <Tooltip title="Documents">
                        <Button className={styles.paymentButton + " btn-hollow"} variant="light" onClick={handleOpen} loading={loading}>
                            <DocumentScannerIcon></DocumentScannerIcon>
                        </Button>
                    </Tooltip>
                    {!payment.CanSignOff ? null : <Button className={styles.paymentButton} variant="primary" onClick={handleClickOpenConfirm} type="success" loading={loading}>
                        Approve
                    </Button>}
                    {!payment.CanSignOff ? null : <Button className={styles.paymentButton} variant="primary" onClick={handleClickOpenReject} type="danger" loading={loading}>
                        Reject
                    </Button>}
              
                    </div>
            </div>
            <div className="nav-action-bar show-on-portrait-phone">
                <div className="action-button-position">
                    <Tooltip title="Documents">
                    <Button className={styles.paymentButton + " btn-hollow"} variant="light" onClick={handleOpen} loading={loading}>
                            <DocumentScannerIcon></DocumentScannerIcon>
                        </Button>
                    </Tooltip>
                    {!payment.CanSignOff ? null : <Button className={styles.paymentButton} variant="primary" onClick={handleClickOpenConfirm} type="success" loading={loading}>
                        Approve
                    </Button>}
                    {!payment.CanSignOff ? null : <Button className={styles.paymentButton} variant="primary" onClick={handleClickOpenReject} type="danger" loading={loading}>
                        Reject
                    </Button>}
                    </div>
            </div>

            <div className="standard-margin">
                {payment.WarningText != null && payment.WarningText != "" &&
                    <Alert severity="success">{payment.WarningText}</Alert>}
               
                <Row className="form-row pl-2 col-xl-12">
                    <Col md={6} sm={12} className="form-group mb-0 col-sm-6">
                        {showCutoffAlert && (
                            <div>
                                <Alert severity="warning">

                                    <AlertTitle>Warning - This has a cut off time: {moment(payment.CutoffTime).format("DD/MM/YYYY, h:mm:ss a")}</AlertTitle>

                                </Alert>
                            </div>)
                        }

                        <Card className="standard-card">

                            <Card.Header>Payment</Card.Header>
                            {/*                        <div className="card default-multi-card">*/}
                            <div className="standard-card-content">
                                <Row className="form-row pl-2 col-xl-12">
                                    <Col md={12} sm={12} className="form-group mb-0 col-sm-6">
                                        <span className={"preserveFormat " + styles.largeFont}>{Number(payment.PaymentAmount).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: payment.PaymentCurrencyDecimalPlaces })} {payment.PaymentCurrencyIsoCode}</span>
                                    </Col>
                                </Row>
                            </div>
                        </Card>

                        <Card className={styles.progressPaymentCard + " standard-card"}>
                            <Card.Header>Progress
                                <Tooltip title="Show Roles">
                                <Button variant="light" className={styles.labelToggle } onClick={toggleLabels}>
                                    <PreviewIcon></PreviewIcon>
                                    </Button>
                                </Tooltip>
                            </Card.Header>
                            <div className={styles.progressPaymentPadding + " standard-card-content"}>
                                <Row className="form-row pl-2 col-xl-12">
                                    <Col md={12} sm={12} className="form-group mb-0 col-sm-6">
                                        <Box sx={{ width: '100%' }}>
                                            <Stepper activeStep={stagesProgress} alternativeLabel>
                                                {(useAlternateLabels ? stagesList : altStagesList).map((label) => (
                                                    <Step key={label}>
                                                        <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </Box>
                                    </Col>
                                </Row>
                            </div>
                        </Card>


                        <Card className="standard-card">
                            <Card.Header>Payment Detail</Card.Header>
                            <div className="standard-card-content">
                                <Row className={"form-row pl-2 col-xl-12 " + styles.autoWidth}>
                                    <Col md={12} sm={12} className={"form-group mb-0 col-sm-6 " + styles.paymentViewDetail}>
                                        <label className="col-form-label font-sm">Detail</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PaymentDescription}</span>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">

                                        <label className="col-form-label font-sm">Date</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{moment(payment.PaymentDate).format("DD/MM/YYYY")}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Payment Amount</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{Number(payment.PaymentAmount).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: payment.PaymentCurrencyDecimalPlaces })} {payment.PaymentCurrencyIsoCode}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Reason</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PaymentReasonDescription}</span>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Type</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PaymentTypeDescription}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Method</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PaymentMethod}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Inbox</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.Inbox}</span>
                                        </div>
                                    </Col>

                           


                                </Row>
                            </div>
                        </Card>

                    </Col>
                    <Col md={6} sm={12} className="form-group mb-0 col-sm-6">
                        <Card className="standard-card">
                            <Card.Header>{payment.PayerAccountholder} <br /> Sending: {Number(payment.DebitAmount).toLocaleString(process.env.CurrencyFormat, { minimumFractionDigits: payment.PayerCurrencyDecimalPlaces })} {payment.PayerCurrencyCode}</Card.Header>
                            <div className="standard-card-content">
                                <Row className="form-row pl-2 col-xl-12">
                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Account Number</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayerAccountNumber}</span>
                                        </div>
                                    </Col>
                                    {payment.PayerIBAN !== null && (
                                        <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                            <label className="col-form-label font-sm">IBAN</label>
                                            <div className="form-control-plaintext pt-0">
                                                <span className="preserveFormat">{payment.PayerIBAN}</span>
                                            </div>
                                        </Col>
                                    )}
                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">CCY</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayerCurrencyCode}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Bank Branch</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayerBankName}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Sort Code</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayerSortCode}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">BIC</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayerBic}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Balance</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{Number(payment.Balance).toLocaleString(process.env.REACT_APP_CurrencyFormat)}</span>
                                        </div>
                                    </Col>

                                    <Col md={12} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Address</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayerAddress}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <Card className="standard-card">
                            <Card.Header>{payment.PayeeAccountholder} <br /> Receiving: {Number(payment.CreditAmount).toLocaleString(process.env.REACT_APP_CurrencyFormat, { minimumFractionDigits: payment.PayeeCurrencyDecimalPlaces })} {payment.PayeeCurrencyCode}</Card.Header>
                            <div className="standard-card-content">
                                <Row className="form-row pl-2 col-xl-12">
                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Account Number</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayeeAccountNumber}</span>
                                        </div>
                                    </Col>
                                    {payment.PayeeIBAN !== null && (
                                        <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                            <label className="col-form-label font-sm">IBAN</label>
                                            <div className="form-control-plaintext pt-0">
                                                <span className="preserveFormat">{payment.PayeeIBAN}</span>
                                            </div>
                                        </Col>
                                    )}
                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">CCY</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayeeCurrencyCode}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Bank Branch</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayeeBankName}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Sort Code</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayeeSortCode}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">BIC</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayeeBic}</span>
                                        </div>
                                    </Col>

                                    <Col md={12} sm={12} className="form-group mb-0 col-sm-6">
                                        <label className="col-form-label font-sm">Address</label>
                                        <div className="form-control-plaintext pt-0">
                                            <span className="preserveFormat">{payment.PayeeAddress}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal
                open={openPDF}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
               
                    <PdfViewer pdfs={pdfFiles} onCancel={handleClose} />
                </div>
            </Modal>
        </div>
    )
}

export default PaymentView;