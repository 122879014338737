import './TableStyles.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function TableExpandableDefaultContent(props) {
    return (
        <Row className="form-row pl-2 col-xl-12 default-content-padding">
            {props.data.map((data, idx) => (
                data.value ? (<Col lg={data.columns} md={data.columns === 12 ? 12 : 6}
                    sm={data.columns === 12 ? 12 : 12} className={'form-group mb-0 col-sm-6 ' + (data.bordered ? 'lower-bordered' : '')} key={idx}>
                    <label className="col-form-label font-sm">{data.label}</label>
                    <div className="form-control-plaintext pt-0 ">
                        <span className="preserveFormat">{data.value ? data.value : 'N/A'}</span>
                    </div>
                </Col>): null
            ))}
           
           
        </Row>
    );
}

export default TableExpandableDefaultContent;