
import SinglePagePDFViewer from "./SinglePagePDF";

import PDFDownload from "./PDFDownload";
import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

const PDFViewer = ({ pdfs, onCancel, showLoading }) => {
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [selectedPdfTitle, setSelectedPdfTitle] = useState(null);
    const [tempPdf, setTempPdf] = useState(null);
    const [showPDFSelection, setShowPDFSelection] = useState(true);

    function handlePdfChange(event) {
        console.log("select")
        console.log(event)
        if (event.target.selectedOptions.length > 0) {
            setSelectedPdfTitle(event.target.selectedOptions[0].text);
        }
        else {
            setSelectedPdfTitle("DownloadFile");
        }
        setTempPdf(event.target.value);
        setSelectedPdf(event.target.value);
    }

    function handlePdfConfirm() {
        setSelectedPdf(tempPdf);
        //setShowPDFSelection(false);
    }

    function handlePdfClose() {
        setShowPDFSelection(false);
    }

    return (
        <div className="App pdfModalMargin">

            <Dialog fullWidth={true} maxWidth={"md"} open={showPDFSelection} onClose={onCancel}>
                <DialogTitle>Select a Document to View</DialogTitle>
                <button className="pdfCloseButton" type="button" onClick={onCancel}>
                    <CloseIcon></CloseIcon>
                </button>
                <DialogContent>
                    {showLoading && pdfs.length === 0 ? (
                        <p>Loading...</p>
                    ) : pdfs.length === 0 ? (
                        <p>No Documents available.</p>
                    ) : (
                        <select value={selectedPdf} onChange={handlePdfChange}>
                            <option value={null}>Select a Document</option>
                            {pdfs.map((pdf, index) => (
                                <option key={index} value={pdf.data}>
                                    {pdf.title}
                                </option>
                            ))}
                        </select>
                    )}
                </DialogContent>
                <DialogActions>
                    {pdfs.length === 0 ? (
                        <Button onClick={onCancel}>Cancel</Button>
                    ) : (
                            <div>
                                {selectedPdf && (
                                    <>
                                        <PDFDownload title={selectedPdfTitle} pdf={selectedPdf} />
                                    </>
                                )}

                              {/*<Button onClick={onCancel}>Cancel</Button>*/}
                              {/*  <Button onClick={handlePdfConfirm}>Select</Button>*/}
                        </div>
                    )}
                </DialogActions>
            </Dialog>

          

           
        </div>
    );
}

  //<hr />
  //                  <button className="pdfCloseButton" type="button" onClick={onCancel}>
  //                      <CloseIcon></CloseIcon>
  //                  </button>
  //                  <SinglePagePDFViewer pdf={selectedPdf} />
  //                  <hr />

export default PDFViewer;